import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Login } from "../../api/functions/auth";
import { Link } from "react-router-dom";
import showToast from "../showToast";

const LoginForm = () => {
  const { setToken } = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSend(true);

    try {
      const formData = new FormData(event.target);
      const values = Object.fromEntries(formData);
      const response = await Login(values);

      if (response.status === 200) {
        setToken(response.data.token);
        showToast("Login successfully!", "success");
      }
    } catch (err) {
      showToast(err.response.data.message, "error");
    } finally {
      setIsSend(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="forms-sample">
        <div className="form-group special_styling mb-3">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            className="form-control special_styling"
            required
          />
        </div>
        <div className="form-group special_styling mb-3">
          <label htmlFor="password">Password</label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              className="form-control special_styling"
              required
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="form-group special_styling mb-3">
          <button type="submit" className="btn btn-primary btn-block">
            {isSend ? "Sending..." : "Send"}
          </button>
        </div>

        <Link to={`/account/register`}>Create new account</Link>
      </form>
    </>
  );
};

export default LoginForm;

import React, { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { createBlog } from "../../api/functions/posts";
import showToast from "../../components/showToast";
import Select from "react-select";
import { Tags as options } from "../../data/Tags";
import uploadIcon from "../../assets/images/icons/upload.svg";

const Create = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState(null);
  const [tags, setTags] = useState([]);
  const [imageName, setImageName] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEditorChange = (content) => {
    setContent(content);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 1024 * 1024 * 10) {
        const img = new Image();
        img.onload = function () {
          if (this.width >= 800 && this.height >= 500) {
            setSelectedImageFile(file);
            setImageName(file.name);
            setPhoto(file);
          } else {
            showToast("Image should be at least 800 x 500", "error");
          }
        };
        img.src = URL.createObjectURL(file);
      } else {
        showToast("Image size should be less than 10MB", "error");
      }
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setTags(selectedOptions);
  };

  const resetData = () => {
    setContent("");
    setPhoto(null);
    setTags([]);
    setSelectedImageFile(null);
    setImageName("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const formDataToSend = new FormData();
    formDataToSend.append("title", title);
    formDataToSend.append("content", content);
    formDataToSend.append("description", description);
    formDataToSend.append("tags", JSON.stringify(tags));
    formDataToSend.append("photo", selectedImageFile);

    try {
      const response = await createBlog(formDataToSend);
      if (response.status === 201) {
        showToast(response.data.message, "success");
        // resetData();
      }
    } catch (error) {
      console.log("response +> ", error.response);
      showToast(error.response?.data?.message || "An error occurred", "error");
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <div id="newBlog" className="main-content">
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          {/* SunEditor */}
          <div className="col-lg-8 col-md-12  col-sm-12 mb-3">
            <SunEditor
              setOptions={{
                height: "600px",
                buttonList: [
                  [
                    "font",
                    "align",
                    "fontSize",
                    "formatBlock",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "fontColor",
                    "textStyle",
                    "list",
                    "link",
                    "fullScreen",
                    "print",
                  ],
                ],
              }}
              onChange={handleEditorChange}
              setContents={content}
            />

            {/* Submit button */}
            <button
              type="submit"
              className="btn btn-success mx-auto mt-3"
              disabled={loading} // Disable button when loading
            >
              {loading ? "Sending..." : "Create new post"}
            </button>
            <button
              type="reset"
              className="btn btn-light mx-auto ms-3 mt-3"
              onClick={resetData}
            >
              Cancel
            </button>
          </div>

          {/* Tags selection */}
          <div className="col-lg-4 col-md-12  col-sm-12">
            {/* Title input */}
            <div className="mb-3">
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="form-control"
                placeholder="Title"
                required
              />
            </div>
            <div className="card mb-3">
              <h4 className="card-title">Tags</h4>
              <div className="card-body">
                <Select
                  isMulti
                  options={options}
                  onChange={handleSelectChange}
                />
              </div>
            </div>
            {/* Description input */}
            <div className="card mb-3">
              <h4 className="card-title">Description</h4>
              <div className="card-body">
                <textarea
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  id="description"
                  placeholder="Add simple description to your blog"
                  cols="30"
                  rows="5"
                  className="form-control"
                ></textarea>
              </div>
            </div>
            {/* File upload */}
            <div className="form-group">
              {selectedImageFile ? (
                <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                  <img
                    src={URL.createObjectURL(selectedImageFile)}
                    alt="Selected"
                    className="mt-2"
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                  <input
                    type="file"
                    name="photo"
                    onChange={handleFileChange}
                    className="file-area__input"
                    placeholder="photo"
                    accept=".jpg, .jpeg, .png"
                    id="uploadCoverImage"
                    required
                  />
                </div>
              ) : (
                <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                  <label
                    className="file-area__label"
                    htmlFor="uploadSliderImage"
                  >
                    <span className="uploader-product-media-file-area__label">
                      <img src={uploadIcon} className="upload-icon" alt="" />
                      <strong className="t3">
                        {imageName ? imageName : "Upload presentational image"}
                      </strong>
                    </span>
                  </label>
                  <input
                    type="file"
                    name="photo"
                    onChange={handleFileChange}
                    className="form-control"
                    placeholder="photo"
                    accept=".jpg, .jpeg, .png"
                    id="uploadCoverImage"
                    required
                  />
                </div>
              )}
              <div className="UploaderProductUploadSection__description t3 ">
                JPG, PNG formats only. File under 10MB.
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* Preview section */}
      {content && (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <h4 className="card-title py-3 py-3 bg-light text-center">
                Preview
              </h4>
              <div
                className="card-body"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create;

export const Tags = [
  { value: "sports", label: "Sports" },
  { value: "music", label: "Music" },
  { value: "technology", label: "Technology" },
  { value: "food", label: "Food" },
  { value: "travel", label: "Travel" },
  { value: "health", label: "Health" },
  { value: "fitness", label: "Fitness" },
  { value: "business", label: "Business" },
  { value: "finance", label: "Finance" },
  { value: "education", label: "Education" },
  { value: "science", label: "Science" },
  { value: "history", label: "History" },
  { value: "art", label: "Art" },
  { value: "literature", label: "Literature" },
  { value: "movies", label: "Movies" },
  { value: "tv_shows", label: "TV Shows" },
  { value: "fashion", label: "Fashion" },
  { value: "photography", label: "Photography" },
  { value: "nature", label: "Nature" },
  { value: "animals", label: "Animals" },
  { value: "cars", label: "Cars" },
  { value: "games", label: "Games" },
  { value: "technology", label: "Technology" },
  { value: "coding", label: "Coding" },
  { value: "programming", label: "Programming" },
  { value: "web_development", label: "Web Development" },
  { value: "web_design", label: "Web Design" },
  { value: "software", label: "Software" },
  { value: "hardware", label: "Hardware" },
  { value: "gadgets", label: "Gadgets" },
  { value: "space", label: "Space" },
  { value: "astronomy", label: "Astronomy" },
  { value: "psychology", label: "Psychology" },
  { value: "philosophy", label: "Philosophy" },
  { value: "religion", label: "Religion" },
  { value: "spirituality", label: "Spirituality" },
  { value: "culture", label: "Culture" },
  { value: "languages", label: "Languages" },
  { value: "politics", label: "Politics" },
  { value: "environment", label: "Environment" },
  { value: "climate_change", label: "Climate Change" },
  { value: "social_issues", label: "Social Issues" },
  { value: "equality", label: "Equality" },
  { value: "human_rights", label: "Human Rights" },
  { value: "activism", label: "Activism" },
  { value: "volunteering", label: "Volunteering" },
  { value: "cooking", label: "Cooking" },
  { value: "recipes", label: "Recipes" },
  { value: "baking", label: "Baking" },
  { value: "restaurants", label: "Restaurants" },
  { value: "culinary_travels", label: "Culinary Travels" },
  { value: "hiking", label: "Hiking" },
  { value: "camping", label: "Camping" },
  { value: "adventure", label: "Adventure" },
  { value: "exploration", label: "Exploration" },
  { value: "backpacking", label: "Backpacking" },
  { value: "sightseeing", label: "Sightseeing" },
  { value: "local_culture", label: "Local Culture" },
  { value: "city_life", label: "City Life" },
  { value: "beach", label: "Beach" },
  { value: "mountains", label: "Mountains" },
  { value: "islands", label: "Islands" },
  { value: "national_parks", label: "National Parks" },
  { value: "gardening", label: "Gardening" },
  { value: "plants", label: "Plants" },
  { value: "flowers", label: "Flowers" },
  { value: "vegetarianism", label: "Vegetarianism" },
  { value: "veganism", label: "Veganism" },
  { value: "nutrition", label: "Nutrition" },
  { value: "diet", label: "Diet" },
  { value: "cooking_techniques", label: "Cooking Techniques" },
  { value: "culinary_history", label: "Culinary History" },
];

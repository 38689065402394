import api from "../http-service";

export const createBlog = (data) => api.post("post", data);

export const getAllBlogs = () => api.get("post");

export const updateBlogById = (id, data) => api.patch(`post/${id}`, data);

export const getBlogByID = (id) => api.get(`post/${id}`);

export const deleteBlogByID = (id) => api.delete(`post/${id}`);

import React, { createContext, useState, useEffect } from "react";
import { getAllBlogs } from "../api/functions/posts";
import showToast from "../components/showToast";
import { getUserInfo } from "../api/functions/auth";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [userData, setUserData] = useState({});

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogRes = await getAllBlogs();
        const userRes = await getUserInfo();

        if (blogRes.status === 200 && userRes.status === 200) {
          setBlogs(blogRes.data.posts);
          setUserData(userRes.data.user);
        }
      } catch (error) {
        showToast(error.response.data.message, "error");
      }
    };

    fetchData();
  }, []);

  const setTokenAndStorage = (newToken) => {
    setToken(newToken);
    localStorage.setItem("token", newToken);
  };

  const removeTokenAndStorage = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  return (
    <AppContext.Provider
      value={{
        token,
        setToken: setTokenAndStorage,
        removeToken: removeTokenAndStorage,
        userData,
        setUserData,

        blogs,
        setBlogs,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

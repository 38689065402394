import React, { useContext } from "react";
import logo from "../assets/images/logo/logo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const Navbar = () => {
  const pages = [];
  const navigate = useNavigate();
  const { removeToken } = useContext(AppContext);

  const logout = () => {
    removeToken();
    navigate("/account/login");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-body-tertiary">
        <div className="container">
          <div className="btn-custom bg-green" onClick={logout}>
            <button>Logout</button>
            <div className="border"></div>
            <div className="border"></div>
            <div className="border"></div>
            <div className="border"></div>
          </div>
          <Link className="navbar-brand" to={"/"}>
            <img src={logo} alt="" />
          </Link>

          <div className="buttons">
            <div className="btn-custom" onClick={() => navigate("/new")}>
              <button>New</button>
              <div className="border"></div>
              <div className="border"></div>
              <div className="border"></div>
              <div className="border"></div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";
import api from "../api/http-service";
import Navbar from "../components/Navbar";

const DefaultLayout = () => {
  const { token } = useContext(AppContext);
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    if (token) {
      checkConnectionStatus();

      const interval = setInterval(() => {
        checkConnectionStatus();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [token]);

  const checkConnectionStatus = () => {
    fetch(api.defaults.baseURL)
      .then((response) => {
        if (!response.ok) {
          setIsConnected(false);
        } else {
          setIsConnected(true);
        }
      })
      .catch(() => {
        setIsConnected(false);
      });
  };

  if (!token) {
    return <Navigate to="/account/login" />;
  }

  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

export default DefaultLayout;

import React, { useState, useEffect } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useParams } from "react-router-dom";
import { getBlogByID, updateBlogById } from "../../api/functions/posts";
import showToast from "../../components/showToast";
import Select from "react-select";
import { Tags as options } from "../../data/Tags";
import uploadIcon from "../../assets/images/icons/upload.svg";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Edit = () => {
  const [imageName, setImageName] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    description: "",
    photo: null,
    tags: [],
  });
  const { id } = useParams();

  const fetchBlogPost = async () => {
    try {
      const response = await getBlogByID(id);
      if (response.status === 200) {
        const { title, content, description, tags } = response.data.blog;
        setBlog(response.data.blog);
        setFormData((prevData) => ({
          ...prevData,
          title: title.en,
          content: content.en,
          description: description.en,
          tags: JSON.parse(tags),
        }));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching blog post:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogPost();
  }, [id]);

  const handleEditorChange = (content) => {
    setFormData((prevData) => ({ ...prevData, content }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 1024 * 1024 * 10) {
        const img = new Image();
        img.onload = function () {
          if (this.width >= 800 && this.height >= 500) {
            setSelectedImageFile(file);
            setImageName(file.name);
            setFormData((prevData) => ({ ...prevData, photo: file }));
          } else {
            showToast("Image should be at least 800 x 500", "error");
          }
        };
        img.src = URL.createObjectURL(file);
      } else {
        showToast("Image size should be less than 10MB", "error");
      }
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData((prevData) => ({ ...prevData, tags: selectedOptions }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("content", formData.content);
      formDataToSend.append("tags", JSON.stringify(formData.tags));
      if (selectedImageFile) {
        formDataToSend.append("photo", selectedImageFile);
      }
      const response = await updateBlogById(id, formDataToSend);

      console.log("res => ", response.data.message);

      if (response.status === 200) {
        showToast(response.data.message, "success");
      }
    } catch (error) {
      showToast(error.response?.data?.message || "An error occurred", "error");
    }
    setLoading(false);
  };

  return (
    <div className="blog main-content">
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-8">
            <SunEditor
              setOptions={{
                height: "800",
                buttonList: [
                  [
                    "font",
                    "align",
                    "fontSize",
                    "formatBlock",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "fontColor",
                    "textStyle",
                    "list",
                    "link",
                    "fullScreen",
                    "print",
                  ],
                ],
              }}
              onChange={handleEditorChange}
              setContents={formData.content}
            />
          </div>
          <div className="col-md-4">
            <div className="mb-3">
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="form-control"
                placeholder="Title"
                required
              />
            </div>

            <div className="card mb-3">
              <h4 className="card-title">Tags</h4>
              <div className="card-body">
                <Select
                  isMulti
                  options={options}
                  value={formData.tags}
                  onChange={handleSelectChange}
                />
              </div>
            </div>

            <div className="card">
              <h4 className="card-title">Description</h4>
              <div className="card-body">
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  id="description"
                  placeholder="Add simple description to your blog"
                  cols="30"
                  rows="5"
                  className="form-control"
                ></textarea>
              </div>
            </div>

            <div className="form-group mt-3">
              {selectedImageFile ? (
                <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                  <img
                    src={URL.createObjectURL(selectedImageFile)}
                    alt="Selected"
                    className="mt-2"
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                  <input
                    type="file"
                    name="photo"
                    onChange={handleFileChange}
                    className="file-area__input"
                    placeholder="photo"
                    accept=".jpg, .jpeg, .png"
                    id="uploadCoverImage"
                  />
                </div>
              ) : (
                <div className="file-area file-area_empty uploader-product-media-file-area uploader-product-media-file-area_type_presentational">
                  {blog.photo && ( // Check if there's an existing image
                    <img
                      src={`${IMAGE_URL}/${blog.photo}`}
                      alt="Existing"
                      className="mt-2"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  )}
                  <label
                    className="file-area__label"
                    htmlFor="uploadSliderImage"
                  >
                    <span className="uploader-product-media-file-area__label">
                      <img src={uploadIcon} className="upload-icon" alt="" />
                      <strong className="t3">
                        {imageName ? imageName : "Upload presentational image"}
                      </strong>
                    </span>
                  </label>
                  <input
                    type="file"
                    name="photo"
                    onChange={handleFileChange}
                    className="form-control"
                    placeholder="photo"
                    accept=".jpg, .jpeg, .png"
                    id="uploadCoverImage"
                  />
                </div>
              )}
              <div className="UploaderProductUploadSection__description t3 ">
                JPG, PNG formats only. File under 10MB.
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-success mx-auto mb-3"
          disabled={loading}
        >
          {loading ? "Updating..." : "Update post"}
        </button>
      </form>
      {formData.content && (
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card">
              <h4 className="card-title py-3 bg-light text-center">Preview</h4>
              <div
                className="card-body"
                dangerouslySetInnerHTML={{ __html: formData.content }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Edit;

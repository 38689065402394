import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBlogByID } from "../../api/functions/posts";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Post = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState({});

  const getBlog = async (id) => {
    try {
      const blogRes = await getBlogByID(id);
      if (blogRes.status === 200) {
        setBlog(blogRes.data.blog);
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    getBlog(id);
  }, [id]);

  const renderContent = () => {
    return <div dangerouslySetInnerHTML={{ __html: blog?.content?.en }} />;
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="blogcard">
              <div className="card-image">
                <img src={IMAGE_URL + `/` + blog?.photo} alt="" />
              </div>
              <h2 className="card-title blog-title">{blog?.title?.en}</h2>
              <div className="card-body">{renderContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;

import { Navigate, Outlet } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";

export default function GuestLayout() {
  const { token } = useContext(AppContext);

  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div id="guestLayout">
      <Outlet />
    </div>
  );
}

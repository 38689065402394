import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./scss/core.scss";
import "./scss/responsive.scss";
import Home from "./pages/Home";
import EditBlog from "./pages/blog/edit";
import CreateBlog from "./pages/blog/create";

import Blog from "./pages/blog/blog";

import DefaultLayout from "./layouts/DefaultLayout";
import GuestLayout from "./layouts/GuestLayout";
import Login from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" index element={<Home />} />
          <Route path="/edit/:id" element={<EditBlog />} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route path="/new" element={<CreateBlog />} />
        </Route>
        <Route path="/account" element={<GuestLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<RegisterPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;

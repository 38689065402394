import React, { useState } from "react";

const TopHeader = () => {
  const [showAlert, setShowAlert] = useState(true);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="">
      {showAlert && (
        <div
          className="topheader alert alert-dismissible fade show"
          role="alert"
        >
          Welcome, sir, to your simple control panel. We will work on developing
          it more and more :)
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={handleCloseAlert}
          ></button>
        </div>
      )}
    </div>
  );
};

export default TopHeader;

import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Register } from "../../api/functions/auth";
import { Link } from "react-router-dom";
import showToast from "../showToast"; // Import showToast here

const RegisterForm = () => {
  const { setToken } = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading state

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading state to true when form is submitted

    const formData = new FormData(event.target);
    const values = {};
    formData.forEach((value, key) => {
      values[key] = value;
    });

    // Validate required fields
    if (
      !values.name ||
      !values.email ||
      !values.password ||
      !values.confirmpassword
    ) {
      showToast("Please fill in all required fields.", "error");
      setIsLoading(false);
      return;
    }

    // Validate password match
    if (values.password !== values.confirmpassword) {
      showToast("Passwords do not match.", "error");
      setIsLoading(false);
      return;
    }

    const valuesToSend = {
      name: values.name,
      email: values.email,
      password: values.password,
      confirmpassword: values.confirmpassword,
    };

    try {
      const response = await Register(valuesToSend);
      setToken(response.data.token);
    } catch (err) {
      showToast(err.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="forms-sample">
        <div className="form-group special_styling mb-3">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            className="form-control special_styling"
            required
          />
        </div>
        <div className="form-group special_styling mb-3">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            className="form-control special_styling"
            required
          />
        </div>
        <div className="form-group special_styling mb-3">
          <label htmlFor="password">Password</label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              className="form-control special_styling"
              required
            />
            <div className="input-group-append">
              <span
                className="input-group-text"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="form-group special_styling mb-3">
          <label htmlFor="confirmpassword">Confirm Password</label>
          <input
            type="password"
            name="confirmpassword"
            id="confirmpassword"
            className="form-control special_styling"
            required
          />
        </div>
        <div className="form-group special_styling mb-3">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Send"}
          </button>
        </div>

        <Link to={`/account/login`}>Already have an account</Link>
      </form>
    </>
  );
};

export default RegisterForm;

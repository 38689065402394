import RegisterForm from "../../components/auth/RegisterForm.jsx";
import "./style.css";

const RegisterPage = () => {
  return (
    <div className="forms-section">
      <div className="forms-box">
        <RegisterForm />
      </div>
    </div>
  );
};

export default RegisterPage;
